import React from "react";
import { connect } from "react-redux";
import { withPage } from "../PageContainer";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import styled from "styled-components";
import * as Ant from "antd";
import constants from "../constants";
import * as Blog from "../../blog/BlogContext";
import ApiArticle from "../../blog/ApiArticle";
import BlogDisplay from "../Components/Blog.display";
import { Helmet } from "react-helmet";

class BlogViewerPage extends React.Component {
  constructor(props) {
    super(props);
    this._api = ApiArticle({ apiHost: constants.serviceBlogApi });
    this.state = {
      loading: true,
      article: null,
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const article = await this._api.getArticleById(this.props.id);
      this.setState({ article });
    } catch (ex) {
      // bypass
    }
    this.setState({ loading: false });
  }

  render() {
    const { loading, article } = this.state;

    if (loading) {
      return (
        <div
          style={{
            paddingTop: 20,
            flex: 1,
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Ant.Spin />
        </div>
      );
    }

    if (!article) {
      return <h2>Article not found</h2>;
    }

    return (
      <div style={{ padding: "0px 15px 15px 15px", backgroundColor: "#fff" }}>
        <Helmet>
          <title>{article.title}</title>
          <meta name="description" content={article.abstract} />
        </Helmet>
        <BlogDisplay blog={article} />
      </div>
    );
  }
}

export default withPage(
  connect(
    (state, ownProps) => ({
      id: Selectors.getQueryParams(ownProps).id,
    }),
    ActionCreator
  )(BlogViewerPage)
);
